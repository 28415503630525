// exporting all functions and constants from original file
export * from 'SourceQuery/Slider.query';

import { StoreConfigFields, StoreConfig } from "./Config.type";
import { Field, Query } from '@tilework/opus';

import {
    SliderQuery as SourceSliderQuery
} from 'SourceQuery/Slider.query';

export class SliderQuery extends SourceSliderQuery {
    //@ts-ignore
    _getSlideFields(): Array<
        Field<'slide_text', string>
        | Field<'slide_id', number>
        | Field<'mobile_image', string>
        | Field<'desktop_image', string>
        | Field<'title', string>
        | Field<'is_active', boolean>
        | Field<'slide_link', string>
    > {
        return [
            new Field<'slide_text', string>('slide_text'),
            new Field<'slide_id', number>('slide_id'),
            new Field<'mobile_image', string>('mobile_image'),
            new Field<'desktop_image', string>('desktop_image'),
            new Field<'title', string>('title'),
            new Field<'is_active', boolean>('is_active'),
            new Field<'slide_link', string>('slide_link'),
        ];
    }
}

export default new SliderQuery();
