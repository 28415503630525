import {
    SliderWidgetComponent as SourceSliderWidgetComponent
} from 'SourceComponent/SliderWidget/SliderWidget.component';
import {SlideWithPlaceholder} from "Component/SliderWidget/SliderWidget.type";
import Link from "Component/Link";
import Image from "Component/Image";
import {ImageRatio} from "Component/Image/Image.type";
import Html from "Component/Html";
import {MouseEvent} from "react";
import {handleOnMouseDown, handleOnMouseUp, checkDragAction} from "Util/DragAction/DragAction";

export class SliderWidgetComponent extends SourceSliderWidgetComponent {
    renderSlideImage(slide: SlideWithPlaceholder, i: number): JSX.Element {
        const { onLoad } = this.props;
        const {
            // @ts-ignore
            slide_link,
            slide_text,
            isPlaceholder,
            title: block,
        } = slide;

        if (slide_link) {
            return (
                <Link
                    block="SliderWidget"
                    elem="Link"
                    to={ {
                        pathname: slide_link
                    } }
                    // @ts-ignore
                    checkWithDragAction={true}
                    checkDragAction={ checkDragAction }
                    showLoader
                >
                    <figure
                        block="SliderWidget"
                        elem="Figure"
                        key={ i }
                        onMouseDown={ handleOnMouseDown }
                        onMouseUp={ handleOnMouseUp }
                    >
                        <Image
                            mix={ { block: 'SliderWidget', elem: 'FigureImage' } }
                            ratio={ ImageRatio.IMG_CUSTOM }
                            src={ this.getSlideImage(slide) }
                            isPlaceholder={ isPlaceholder }
                            onImageLoad={ onLoad }
                        />
                        <figcaption
                            block="SliderWidget"
                            elem="Figcaption"
                            mix={ { block } }
                        >
                            <Html content={ slide_text || '' } />
                        </figcaption>
                    </figure>
                </Link>

            );
        } else {
            return super.renderSlideImage(slide, i);
        }
    }
}

export default SliderWidgetComponent;
