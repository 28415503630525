import {
    ProductGalleryComponent as SourceProductGalleryComponent
} from 'SourceComponent/ProductGallery/ProductGallery.component';
import {RouteComponentProps, withRouter} from "react-router";
import {ComponentType} from "react";
import {ProductGalleryComponentProps} from "Component/ProductGallery/ProductGallery.type";
import {ReactElement} from "Type/Common.type";
import Slider from "Component/Slider";
import './ProductGallery.override.style';


export class ProductGalleryComponent extends SourceProductGalleryComponent {
    renderSlider(): ReactElement {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            isMobile,
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive,
        };

        const isMoreThanOnePhoto = gallery.length > 1;

        // @ts-ignore
        const isThumbnailImage = gallery[0].is_thumbnail || false;

        return (
            <div
                ref={ this.imageRef }
                block="ProductGallery"
                elem="SliderWrapper"
                data-is-pdp
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                    sliderRef={ sliderRef }
                    mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                    showCounter={ isMobile }
                    showArrows={ !isMobile && isMoreThanOnePhoto }
                    activeImage={ activeImage }
                    onActiveImageChange={ onActiveImageChange }
                    isInteractionDisabled={ isZoomEnabled }
                    onClick={ this.handleSliderClick }
                    sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                    //@ts-ignore
                    numberOfSlider={ gallery.length }
                    isHeightTransitionDisabledOnMount
                >
                    { isThumbnailImage ? [this.renderPlaceholderImage()] : gallery.map(this.renderSlide)}
                </Slider>
            </div>
        );
    }
}

export default withRouter(
    ProductGalleryComponent as unknown as ComponentType<
        RouteComponentProps & ProductGalleryComponentProps
        >,
);
