import {
    ProductGalleryContainer as SourceProductGalleryContainer
} from 'SourceComponent/ProductGallery/ProductGallery.container';
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "SourceComponent/ProductGallery/ProductGallery.container";
import {MediaGalleryEntry} from "Query/ProductList.type";
import {AMOUNT_OF_PLACEHOLDERS, MediaType, THUMBNAIL_KEY} from "Component/ProductGallery/ProductGallery.config";

export class ProductGalleryContainer extends SourceProductGalleryContainer {
    getGalleryPictures(): MediaGalleryEntry[] {
        const {
            areDetailsLoaded,
            product: {
                media_gallery_entries: mediaGallery = [],
                [ THUMBNAIL_KEY ]: { url: thumbnailUrl = '' } = {},
                [ MediaType.IMAGE ]: { url: imageTypeUrl = '' } = {},
                name,
            },
        } = this.props;

        const url = imageTypeUrl || thumbnailUrl;

        if (mediaGallery.length) {
            return mediaGallery
                .filter(({ disabled }) => !disabled)
                .sort((a, b) => a.position - b.position);
        }

        if (!url) {
            return Array(AMOUNT_OF_PLACEHOLDERS + 1).fill({ media_type: 'placeholder' });
        }

        const placeholders = !areDetailsLoaded
            ? Array(AMOUNT_OF_PLACEHOLDERS).fill({ media_type: 'placeholder' }) : [];

        return [
            {
                thumbnail: { url },
                base: { url },
                id: THUMBNAIL_KEY,
                label: name,
                media_type: MediaType.IMAGE,
                is_thumbnail: true,
            },
            ...placeholders,
        ];
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
