import {MouseEvent} from "react";

let prevClientXonMouseDown = 0,
    prevClientYonMouseDown = 0,
    clientXonMouseDown = 0,
    clientYonMouseDown = 0;

export function handleOnMouseDown (e: MouseEvent): void {
    prevClientXonMouseDown = e.clientX;
    prevClientYonMouseDown = e.clientY;
    e.preventDefault() // stops weird link dragging effect
}
export function handleOnMouseUp(e: MouseEvent): void {
    clientXonMouseDown = e.clientX;
    clientYonMouseDown = e.clientY;
    e.preventDefault() // stops weird link dragging effect
}
//@ts-ignore
export function checkDragAction(): boolean {
    if (clientXonMouseDown !== prevClientXonMouseDown || clientYonMouseDown !== prevClientYonMouseDown) {
        // prevent link click if the element was dragged
        return true;
    }
    return false;
}
