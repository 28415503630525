import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import {Children, ReactElement} from 'Type/Common.type';

import './ProductCard.override.style';
import {appendWithStoreCode, isHomePageUrl} from "Util/Url";
import {ProductType} from "Component/Product/Product.config";
import ProductPrice from "Component/ProductPrice";
import Link from "Component/Link";
import { MouseEvent } from 'react';
import {ProductCardComponentProps} from "Component/ProductCard/ProductCard.type";
import {transitionElementFromTo} from "Util/ElementTransition/ElementTransition";
import {scrollToTop} from "Util/Browser/Browser";
import {handleOnMouseDown, handleOnMouseUp, checkDragAction} from "Util/DragAction/DragAction";

/** @namespace VusaApp/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    renderBrand(): ReactElement {
        return (<div />);
    }

    renderPrice(): ReactElement {
        const {
            getActiveProduct,
            productPrice,
            product: {
                type_id: baseType,
            } = {},
            inStock,
        } = this.props;

        const {
            price_range: priceRange,
            price_tiers: priceTiers,
            type_id: type,
        } = getActiveProduct();

        if (!priceRange) {
            return this.renderTextPlaceholder();
        }

        return (
            <div
                block={ this.className }
                elem="PriceWrapper"
            >
                <ProductPrice
                    price={ productPrice }
                    priceType={ type as ProductType }
                    tierPrices={ priceTiers }
                    isPreview={ baseType !== ProductType.CONFIGURABLE || type === baseType }
                    isSchemaRequired={ false }
                    mix={ { block: this.className, elem: 'Price' } }
                />
            </div>
        );
    }

    renderVisibleOnHover(): ReactElement {
        const { device } = this.props;

        if (device.isMobile) {
            return null;
        }
        const { pathname = appendWithStoreCode('/') } = location;

        return (
            <>
                { this.renderConfigurableOptions() }
                <div block="ProductCard" elem="Footer">
                    { !isHomePageUrl(pathname) ? this.renderProductActions() : '' }
                </div>
            </>
        );
    }

    renderCardLinkWrapper(children: Children, mix = {}): ReactElement {
        const { linkTo = '', product: { url } } = this.props;

        if (!url) {
            return (
                <div
                    block="ProductCard"
                    elem="Link"
                >
                    { children }
                </div>
            );
        }

        return (
            <div block="ProductCard" elem="Link" onMouseDown={ handleOnMouseDown } onMouseUp={ handleOnMouseUp }>
                <Link
                    block="ProductCard"
                    elem="Link"
                    to={ linkTo }
                    //@ts-ignore
                    checkWithDragAction={true}
                    checkDragAction={ checkDragAction }
                    onClickBeforeLoader={ this.handleLinkClick }
                    showLoader
                    mix={ mix }
                >
                    { children }
                </Link>
            </div>
        );
    }
}

export default ProductCardComponent;
